import "./Home.css";
// import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div id="Home">
      <div class="apresentacao">
        {/* <p>Explore interiores únicos, enconstre inspiração e compartilhe seu refúgio dos sonhos</p>
        <NavLink className="btn" to="/login">EXPLORAR</NavLink> */}
      </div>
    </div>
  );
};

export default Home;
