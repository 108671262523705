import './Footer.css';

const Footer = () => {
  return (
    <footer id="footer">
        <p>Orangerie &copy; 2023</p>
    </footer>
  )
}

export default Footer;